import PropTypes from 'prop-types';
import React from 'react';
import {Toast, ToastContainer} from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {hideToasterAction} from '../../store/shared/Toaster/ToasterAction';
import './Toaster.scss';

const Toaster = ({toaster, hideToaster}) => {
  const {isOpen, message, type} = toaster;
  let errorMsg = [];
  if (toaster.errorObj) {
    const payload = toaster.errorObj?.payload;
    if (payload) {
      if (Array.isArray(payload)) {
        if (payload.length) {
          errorMsg.push(payload);
        } else {
          errorMsg.push(toaster.errorObj?.payload.message);
        }
      } else if (Object.keys(payload).length) {
        Object.keys(payload).forEach((key) => {
          if (Array.isArray(payload[key])) {
            errorMsg = errorMsg.concat(payload[key]);
          } else {
            if (key === 'message') {
              errorMsg.push(payload[key]);
            }

          }
        });
      }
    } else {
      errorMsg.push(toaster.errorObj?.payload || toaster.errorObj?.defaultMsg);
    }
  }
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    hideToaster();
  };

  return (
    <ToastContainer position='top-end'>
      <Toast
        onClose={() => handleClose()}
        show={isOpen}
        delay={3000}
        autohide={toaster?.errorObj?.hideOnClick ? false : true}
        bg={type === 'error' ? 'danger' : 'success'}
      >
        <Toast.Body>
          {errorMsg.map((error, index) => {
            return <div onClick={() => toaster?.errorObj?.hideOnClick ? handleClose(): null} key={index}>{error}</div>;
          })}
          {message}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

Toaster.propTypes = {
  toaster: PropTypes.object,
  hideToaster: PropTypes.func,
  errorObj: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    toaster: state.Toaster,
  };
};

const mapDispatchToProps = (dispatch) => ({
  hideToaster: bindActionCreators(hideToasterAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toaster);
