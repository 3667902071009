import * as LazyComponent from '../utils/LazyLoaded';

export const sharedRoutes = [
  {
    exact: false,
    path: 'about-us',
    component: LazyComponent.AboutUs,
    isPublic: true,
  },
  {
    exact: false,
    path: 'careers',
    component: LazyComponent.Careers,
    isPublic: true,
  },
  {
    exact: false,
    path: 'insurance',
    component: LazyComponent.Insurance,
    isPublic: true,
  },
  {
    exact: false,
    path: 'primary-care',
    component: LazyComponent.PrimaryCare,
    isPublic: true,
  },
  {
    exact: false,
    path: 'direct-care',
    component: LazyComponent.DirectCare,
    isPublic: true,
  },
];
