import moment from 'moment';
import {all, call, takeLatest} from 'redux-saga/effects';
import Auth from '../../../utils/Auth';
import {dispatchToasterError, dispatchToasterSuccess} from '../../../utils/Shared';
import * as API from './UserApis';
import * as MSG from './UserMessages';
import * as TYPES from './UserTypes';

export function* registerSaga(action) {
  try {
    // yield call(API.register, {
    //   email: action.payload.email,
    //   password: action.payload.password,
    //   fullName: action.payload.fullName,
    //   mobileNumber: action.payload.mobileNumber,
    // });
    const resp = yield call(API.login, {
      email: action.payload.email,
      password: action.payload.password,
    });
    yield call(Auth.signIn, resp?.data || {});
    yield call(action.successCallback);
  } catch (err) {
    dispatchToasterError({
      payload: err,
      defaultMsg: MSG.loginFailed,
    });
    if (action.loaderCallback) {
      yield call(action.loaderCallback);
    }
  } finally {
    if (action.loaderCallback) {
      yield call(action.loaderCallback);
    }
  }
}

export function* loginSaga(action) {
  try {
    const resp = yield call(API.login, {
      email: "medsurgeapp@mailinator.com",
      password: "Password@1",
    });
    yield call(Auth.signIn, resp?.data || {});
    yield call(action.successCallback);
  } catch (err) {
    dispatchToasterError({
      payload: err,
      defaultMsg: MSG.loginFailed,
    });
    if (action.loaderCallback) {
      yield call(action.loaderCallback);
    }
  } finally {
    if (action.loaderCallback) {
      yield call(action.loaderCallback);
    }
  }
}

export function* getSlotsSaga(action) {
  try {
    let slots = yield call(API.getSlots, action.payload);
    if (moment().format('YYYY-MM-DD') === action.payload && slots?.data.length) {
      slots.data?.shift();
    }
    yield call(action.successCallback, slots.data);
    yield call(action.loaderCallback);
  } catch (err) {
    yield call(action.successCallback, []);
    yield call(action.loaderCallback);
    dispatchToasterError({
      payload: err?.response?.data,
      defaultMsg: MSG.getSlotFailed,
    });
  }
}

export function* bookSlotsSaga(action) {
  try {
    const slots = yield call(API.selectSlots, action.payload);
    dispatchToasterSuccess({defaultMsg: `Slot booked for ${action.payload.appointmentDate}`, hideOnClick: true});
    yield call(action.successCallback, slots.data);
  } catch (err) {
    dispatchToasterError({
      payload: err?.response?.data,
      defaultMsg: MSG.getSlotFailed,
    });
  } finally {
  }
}

export function* UserSagas() {
  yield all([
    takeLatest(TYPES.GET_SLOTS, getSlotsSaga),
    takeLatest(TYPES.REGISTER, registerSaga),
    takeLatest(TYPES.LOGIN, loginSaga),
    takeLatest(TYPES.BOOK_SLOTS, bookSlotsSaga),
  ]);
}
