/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Suspense, useEffect} from 'react';
import {useLocation} from 'react-router';
import {Route, Routes as ReactRouterRoutes} from 'react-router-dom';
import Loader from '../components/Loader';
import {sharedRoutes} from '../constants';
import * as LazyComponent from '../utils/LazyLoaded';

const Routes = () => {
  const location = useLocation();
  useEffect(() => {
  }, [location]);

  return (
    <Suspense fallback={<Loader />}>
      <ReactRouterRoutes>
        <Route path='/' element={<LazyComponent.Dashboard />} />
        {sharedRoutes.map((route) => (
          <Route key={route.path} path={`/${route.path}`} element={<route.component />} />
        ))}
      </ReactRouterRoutes>
    </Suspense>
  );
};

Routes.propTypes = {
};

Routes.defaultProps = {
};

export default Routes;
