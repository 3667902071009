import React from 'react';
import './App.scss';
import Toaster from './components/Toaster';
import Header from './containers/Header';
import Footer from './containers/Footer';
import Routes from './routes/Routes';

const App = () => {
  return (
    <>
      <Toaster />
      <div className='p-100'>
        <Header />
        <Routes />
      </div>
      <Footer />
    </>
  );
};

export default App;
