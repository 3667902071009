/**
 * Service to check authentication for user and to signOut
 */
const Auth = {
  isAuth() {
    return localStorage.getItem('authToken');
  },
  getToken() {
    const token = {};
    token.authToken = localStorage.getItem('authToken');
    token.refreshToken = localStorage.getItem('refreshToken');
    return token;
  },
  getUserdata() {
    return localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData'));
  },
  signIn(payload) {
    const {tokens} = payload;
    localStorage.setItem('authToken', tokens.access?.token);
    localStorage.setItem('refreshToken', tokens.refresh?.token);
    localStorage.setItem('userData', JSON.stringify(payload.user));
  },
  signOut() {
    localStorage.clear();
  },
};
export default Auth;
