export const BUTTON_TYPES = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  danger: 'danger',
  dark: 'dark',
  outlineDark: 'outline-dark',
  light: 'light',
  purple: 'purple',
};
