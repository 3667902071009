import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import {watchSagas} from './sagas';

const saga = createSagaMiddleware();
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const createStoreWithMiddleware = applyMiddleware(saga);
const enhancer = composeEnhancers(createStoreWithMiddleware);

const store = createStore(reducers, enhancer);

saga.run(watchSagas);

export default store;
