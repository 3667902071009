import React from 'react';
import './footer.scss';
import {Col, Row} from 'react-bootstrap';

const Footer = () => {
  return (
    <>
      <div className='footer bg-dark text-white'>
        <div className='content'>
          <Row className='footer-title'>
            <Col xs={12}>
            <div className='logo-title'>
                <span className='purple'>MedSurg</span></div>
            </Col>
            {/* <Col xs={2}></Col>
            <Col xs={4}>Contact Us</Col> */}
            {/* <Col xs={2}></Col>
            <Col xs={4}>Solutions</Col> */}
          </Row>
          <Row>
            <Col sm={12}>
              <div className='footer-desc'>
                <div className='desc'>
                MEDSURG URGENT CARE is founded in 2018 by board-certified physicians to provide responsive, compassionate, immediate care on a walk-in basis to patients with urgent medical needs. Our modern, clean and comfortable facilities are equipped with state-of-the art digital X-ray, point of care lab and EKG equipment. We can perform inhouse testing for common medical problems and dispense commonly prescribed medications.
                </div>
                <div className='desc'>
                Our staff, team of physicians and mid-level providers strive to provide our patients with thorough, individualized attention without the long wait that might be experienced at an emergency room or larger medical facility
                </div>
              </div>
            </Col>

            {/* <Col sm={4}>
              <div className='footer-desc pl-40'>
                <div className='desc'>SRA Number: 123456, </div>
                <div className='desc'>Company Number: 87654321.</div>
              </div>
            </Col>
            <Col sm={2}></Col>
            <Col sm={4}>
              <div className='footer-desc'>
                <div className='desc'>Our Fees </div>
                <div className='desc'>Complaints Procedure</div>
                <div className='desc'>Privacy Notice</div>
              </div>
            </Col>*/}
          </Row>
          {/* <div className='copy-right'>Copyright 2022 @ BrightHR . All rights reserved.</div> */}
        </div>
      </div>
    </>
  );
};

Footer.propTypes = {};

export default Footer;
