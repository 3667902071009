import {
  GET_SLOTS_RESPONSE
} from './UserTypes';

const INITIAL_STATE = {
  isLoggedOut: false,
  slots: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SLOTS_RESPONSE: {
      return {...state, slots: action.payload.slots};
    }

    default:
      return state;
  }
};
