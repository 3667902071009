import {combineReducers} from 'redux';
import Loader from '../shared/Loader/LoaderReducer';
import Toaster from '../shared/Toaster/ToasterReducer';
import User from '../shared/User/UserReducer';

export default combineReducers({
  User,
  Toaster,
  Loader,
});
