import React from 'react';
import Logo1 from '../../assets/images/logo1.jpeg';
import {Nav, Navbar} from 'react-bootstrap';
import './header.scss';
import {useNavigate} from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='navbar-container'>
        <Navbar bg='light' expand='lg'>
          <Navbar.Brand href='#'>
            <div>
              <img src={Logo1} alt={''} className='logo-icon-med' />
            </div>
            <div className='logo-title' onClick={() => navigate('/')}>
              <div>
                <span className='purple'>MedSurg</span>
              </div>
            </div>
            <div className='help-text'>For urgent family needs</div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll'>
            <Nav
              className='ml-auto my-2 my-lg-0 medsurg-header'
              style={{maxHeight: '100px'}}
              navbarScroll
            >
              <Nav.Link onClick={() => navigate('/')}>Home</Nav.Link>
              <Nav.Link onClick={() => navigate('/about-us')}>About Us</Nav.Link>
              <Nav.Link onClick={() => navigate('/primary-care')}>Primary Care Patients</Nav.Link>
              <Nav.Link onClick={() => navigate('/direct-care')}>MedSurg Direct Care</Nav.Link>
              <Nav.Link onClick={() => navigate('/insurance')}>Insurance Accepted</Nav.Link>
              <Nav.Link onClick={() => navigate('/careers')}>Careers</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
};

Header.propTypes = {};

export default Header;
