import axiosInstance, {microServices} from '../../../network/apis';

export const register = async (data) => {
  return await axiosInstance('post', `user/register`, data, {
    server: microServices.GLOBAL_ADMIN_URL,
  });
};

export const login = async (data) => {
  return await axiosInstance('post', 'user/login', data, {
    server: microServices.GLOBAL_ADMIN_URL,
  });
};

export const getSlots = async (date) => {
  return await axiosInstance('get', `appointment/slots?date=${date}`, {
    server: microServices.GLOBAL_ADMIN_URL,
  });
};

export const selectSlots = async (data) => {
  return await axiosInstance('post', `appointment/create`, data, {
    server: microServices.GLOBAL_ADMIN_URL,
  });
};
